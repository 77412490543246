import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useLogOut from "@/hooks/useLogOut";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import Icon from "../ui/Icon";
import { En, Fa } from "../ui/multilang";
import UserMeAvatar from "../UserMeAvatar";

export function AccountDropdownMenu() {
  const logoutM = useLogOut();
  const { locale } = useRouter();

  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <button className="pis-2">
          <UserMeAvatar />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 bg-gray1 " align="end" dir={locale === "fa" ? "rtl" : "ltr"}>
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Icon name="bf-i-ph-user mie-2" />
            <Link href={`/account`} className="w-full" onClick={() => setOpen(false)}>
              <En>Dashboard</En>
              <Fa>دشبورد</Fa>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <Icon name="bf-i-ph-lifebuoy mie-2" />
          <Link target="_blank" href="https://wa.me/18773703277" className="w-full">
            <En>Support</En>
            <Fa>پشتیبانی</Fa>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <button onClick={() => logoutM.mutate()} className="w-full text-start">
            <Icon name="bf-i-ph-sign-out mie-2" />
            <En>Logout</En>
            <Fa>خروج</Fa>
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
