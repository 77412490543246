import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function calcPackageCost(selectedCourse, numberOfSessions, topicId, isForDebt = false) {
  const now = new Date();
  const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString().split("T")[0];

  let sessionCalculatedCost = null;
  let normalSessionCost = null;
  let durationFactor = null;
  let studentCountFactor = null;
  let sessionsCountFactor = null;
  let sessionDurationOnWebsite = null;
  let requestedDuration = null;
  let studentsCount = null;
  let sessionsCount = null;
  const sessionsCountMap = {
    1: 1.15,
    10: 0.95,
    13: 0.95,
    15: 0.95,
    20: 0.95,
  };

  normalSessionCost = selectedCourse.teacher.expertise.find(
    (exp) => exp.topicId === topicId && exp.startDate <= todayDate && (exp.endDate === null || exp.endDate > todayDate)
  ).sessionPriceInCAD;
  sessionDurationOnWebsite = selectedCourse.teacher.expertise.find(
    (exp) => exp.topicId === topicId && exp.startDate <= todayDate && (exp.endDate === null || exp.endDate > todayDate)
  ).sessionDurationOnWebsiteInMinute;
  requestedDuration = selectedCourse.requestedSessionDurationInMinute;
  studentsCount = selectedCourse.numberOfStudents;
  sessionsCount = numberOfSessions;
  sessionsCountFactor = isForDebt ? 1 : sessionsCountMap[sessionsCount] ?? 1;
  durationFactor = requestedDuration ? requestedDuration / sessionDurationOnWebsite : 1;
  studentCountFactor = (studentsCount - 1) * 0.2 + 1;
  sessionCalculatedCost = sessionsCountFactor * durationFactor * studentCountFactor * normalSessionCost * sessionsCount;

  return sessionCalculatedCost;
}

export function checkCouponStatus(couponData, userInfo, courseInfo, numberOfSessions) {
  const now = new Date();
  const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString().split("T")[0];
  let result = { amount: 0, percent: 0, message: "" };
  if (couponData.length === 0) {
    result.message = "Coupon not found";
    return result;
  }
  if (couponData[0].condition.validFrom > todayDate) {
    result.message = "Coupon is not valid yet";
    return result;
  }
  if (
    couponData[0].condition.usedCount >= couponData[0].condition.maxUse ||
    couponData[0].condition.validTo < todayDate
  ) {
    result.message = "Coupon is expired";
    return result;
  }
  if (couponData[0].condition.userId.length > 0 && !couponData[0].condition.userId.includes(userInfo.payer.id)) {
    result.message = "Coupon is not valid for you";
    return result;
  }
  if (couponData[0].condition.usedUser.includes(userInfo.payer.id)) {
    result.message = "You already used this coupon";
    return result;
  }
  if (
    couponData[0].condition.numberOfSessions.length > 0 &&
    !couponData[0].condition.numberOfSessions.includes(parseInt(numberOfSessions))
  ) {
    result.message = "Coupon is not valid for the specified number of sessions";
    return result;
  }
  if (
    couponData[0].condition.categoryId.length > 0 &&
    !couponData[0].condition.categoryId.includes(courseInfo.categoryId)
  ) {
    result.message = "Coupon is not valid for this category";
    return result;
  }
  if (couponData[0].condition.topicId.length > 0 && !couponData[0].condition.topicId.includes(courseInfo.topicId)) {
    result.message = "Coupon is not valid for this topic";
    return result;
  }
  if (
    couponData[0].condition.teacherId.length > 0 &&
    !couponData[0].condition.teacherId.includes(courseInfo.teacherId)
  ) {
    result.message = "Coupon is not valid for this teacher";
    return result;
  }
  // If the coupon is valid, set the amount or percentage and message
  if (couponData[0].condition.amount > 0) {
    result.amount = couponData[0].condition.amount;
  } else {
    result.percent = couponData[0].condition.percent;
  }
  result.message = "Coupon is valid";
  return result;
}
