import { renderNoData } from "@/components/RenderQ";
import { TableR } from "@/components/Table";
import { DEFAULT_TEACHER_COMMISSION } from "@/constants";
import { frmCAD } from "@/utils/formatter";
import { useMonths } from "@/components/MonthsProvider";
import { calcSessionCost } from "@/pages/teacher-dashboard/payments";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useGetUserMe from "@/hooks/useGetUserMe";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import toast from "react-hot-toast";

const Invoice = () => {
  const months = useMonths();
  const selectedMonth = months[1];
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teacherPhone, setTeacherPhone] = useState(null);
  const [signature, setSignature] = useState<string | null>(null);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

  const saveSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL();
      // Check if the signature canvas is empty
      if (sigCanvas.current.isEmpty()) {
        setIsSignatureEmpty(true);
      } else {
        setSignature(dataUrl);
        setIsSignatureEmpty(false);
      }
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setIsSignatureEmpty(true); // Signature is empty
    }
  };

  const saveAsPDF = async () => {
    setIsSubmitting(true);
    const invoiceElement = document.getElementById("invoice");
    if (!invoiceElement) return;
    const desiredWidth = Math.max(document.documentElement.clientWidth, 850);

    const canvas = await html2canvas(invoiceElement, {
      scale: 0.8,
      width: desiredWidth, // include padding
      height: invoiceElement.clientHeight, // include padding
      scrollY: -window.scrollY,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a5");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    const pdfBlob = pdf.output("blob");
    const userNameAndDate =
      userMeQ?.data && userMeQ.data.firstname + " " + userMeQ.data.lastname + " " + selectedMonth.yearMonth;
    async function uploadFile(file) {
      const { data, error }: any = await supabase.storage.from("invoice").upload(`${userNameAndDate}.pdf`, file);
      if (error) {
        if (error.statusCode === "409") {
          const { error: invoiceSubmittedError } = await supabase
            .from("teacher")
            .update({ invoiceSubmitted: true })
            .eq("id", userMeQ.data?.teacher.id);

          if (invoiceSubmittedError) {
            toast.error("Error updating invoiceSubmitted: " + invoiceSubmittedError.message);
          } else {
            queryClient.invalidateQueries({ queryKey: ["invoiceSubmitted-1"] });
          }
        } else {
          toast.error("Error uploading invoice: " + error.message);
        }
      } else {
        const { error: invoiceSubmittedError } = await supabase
          .from("teacher")
          .update({ invoiceSubmitted: true })
          .eq("id", userMeQ.data?.teacher.id);

        if (invoiceSubmittedError) {
          toast.error("Error updating invoiceSubmitted: " + invoiceSubmittedError.message);
        } else {
          queryClient.invalidateQueries({ queryKey: ["invoiceSubmitted-1"] });
        }
      }
    }
    uploadFile(pdfBlob);
  };

  const supabase = useSupabaseClient();
  const userMeQ = useGetUserMe();
  const teacherName = userMeQ?.data && userMeQ.data.firstname + " " + userMeQ.data.lastname;
  const teacherEmail = userMeQ?.data.email;

  const fetchSensitiveInfo = async ({ userId, column }) => {
    const response = await fetch("/api/getSensitiveInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, column }),
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.error);
    }
    return result.data[column];
  };

  useEffect(() => {
    if (!userMeQ?.data?.id) return;

    const fetchPhone = async () => {
      try {
        const phone = await fetchSensitiveInfo({ userId: userMeQ.data.id, column: "phone" });
        setTeacherPhone(phone);
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    };

    fetchPhone();
  }, [userMeQ?.data?.id]);

  console.log(teacherPhone);

  const sessionQ = useQuery({
    queryKey: ["sessionTeacherDash-4"],
    enabled: !!userMeQ.data?.teacher.id,
    queryFn: async () => {
      const { data, error } = await supabase
        .from("session")
        .select(
          `
      isHeld, date, id,
    course!inner  (
      id, requestedSessionDurationInMinute, topicId , topic(name), numberOfStudents,
      courseStudent (id, student(first_name, last_name)),
      teacher ( id , commissionPercentage,
        user ( id , firstname , lastname, firstnameFa , lastnameFa),
        expertise ( id , sessionPriceInCAD , sessionDurationOnWebsiteInMinute, startDate , endDate, topicId, teacherId )
      )
    ),
    packagePurchased (
      id , numberOfSessions , session_count_rate,
      expertise ( id,  sessionPriceInCAD , sessionDurationOnWebsiteInMinute, startDate , endDate, topicId, teacherId )
    )
    `
        )
        .filter("course.teacherId", "eq", userMeQ.data?.teacher.id);
      if (error) throw error;
      return data;
    },
  });

  useEffect(() => {
    const updateInvoiceSubmitted = async () => {
      if (sessionQ.data) {
        const monthSessions = sessionQ.data.filter((s) => selectedMonth.includes(s.date));
        if (monthSessions.length === 0) {
          const { error: invoiceSubmittedError } = await supabase
            .from("teacher")
            .update({ invoiceSubmitted: true })
            .eq("id", userMeQ.data?.teacher.id);

          if (invoiceSubmittedError) {
            toast.error("Error updating invoiceSubmitted: " + invoiceSubmittedError.message);
          } else {
            queryClient.invalidateQueries({ queryKey: ["invoiceSubmitted-1"] });
          }
        }
      }
    };

    updateInvoiceSubmitted();
  }, [sessionQ.data]);

  const giftQ = useQuery({
    queryKey: ["giftTeacherDash-2"],
    enabled: !!userMeQ.data?.teacher.id,
    queryFn: async () => {
      const { data, error } = await supabase
        .from("giftForTeacher")
        .select(`*`)
        .eq("teacherId", userMeQ.data?.teacher.id);
      if (error) throw error;
      return data;
    },
  });

  return (
    <>
      <p className="info-line mx-10 mt-10">To access your dashboard, please check and confirm the below invoice.</p>
      <div className="px-10 py-10" id="invoice">
        {renderNoData(sessionQ) ?? renderNoData(giftQ) ?? (
          <>
            <PaymentsTable
              userMeQ={userMeQ}
              selectedMonth={selectedMonth}
              sessions={sessionQ.data}
              gifts={giftQ.data}
              teacherName={teacherName}
              teacherEmail={teacherEmail}
              teacherPhone={teacherPhone}
            />
            <p className="italic c-sand11">
              Billing Period: {months[2].yearMonth.split(" ")[1]} 26th, {months[2].yearMonth.split(" ")[0]} -{" "}
              {selectedMonth.yearMonth.split(" ")[1]} 26th, {selectedMonth.yearMonth.split(" ")[0]}
            </p>

            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas border rounded-xl shadow bg-white my-4" }}
            />
            <div className="flex gap-4 mb-10 mx-10 mt-4">
              <button onClick={clearSignature} className="btn-ghost-prm">
                Clear
              </button>
              <button onClick={saveSignature} className="btn-prm">
                Save
              </button>
            </div>
          </>
        )}
      </div>
      <button onClick={saveAsPDF} disabled={isSubmitting || isSignatureEmpty} className="mb-10 mx-10 btn-prm">
        {isSubmitting ? "Submitting..." : "Send invoice"}
      </button>
    </>
  );
};

export default Invoice;

const PaymentsTable = ({ sessions, gifts, selectedMonth, userMeQ, teacherName, teacherEmail, teacherPhone }) => {
  const monthSessions = sessions.filter((s) => selectedMonth.includes(s.date));
  const monthGifts = gifts.filter((g) => selectedMonth.includes(g.date));

  return (
    <div className="space-y-6">
      <div className="space-y-2"></div>
      {monthSessions && monthSessions.length > 0 && (
        <PageContent
          selectedMonth={selectedMonth}
          teacherName={teacherName}
          teacherEmail={teacherEmail}
          teacherPhone={teacherPhone}
          monthGifts={monthGifts}
        >
          {monthSessions.map((se) => {
            let row = { ...se };

            delete row.packagePurchased;
            delete row.course;

            row = {
              date: new Date(row.date),
              subject: se.course.topic.name,
              ...calcSessionCost(se),
            };
            delete row.Students;
            delete row["Was Session Held"];
            delete row.hasPackage;
            const commission = userMeQ.data.teacher.commissionPercentage ?? DEFAULT_TEACHER_COMMISSION;

            row["Cost"] = frmCAD(row["Cost"] * (100 - commission) * 0.01);

            return row;
          })}
        </PageContent>
      )}
    </div>
  );
};

const PageContent = ({ children, selectedMonth, teacherName, teacherEmail, teacherPhone, monthGifts }) => {
  const rows = children;
  // please calculate the sum of gifts using reduce
  const giftsSum = monthGifts.length > 0 ? monthGifts.reduce((acc, gift) => acc + gift.amountInCAD, 0) : 0;

  return (
    <>
      <div>
        <div className="italic c-sand11">
          <p>
            Date: {selectedMonth.yearMonth.split(" ")[1]} 26th, {selectedMonth.yearMonth.split(" ")[0]}
          </p>
          <p>Billed From:</p>
          <p>{teacherName}</p>
          <p>{teacherEmail}</p>
          <p>{teacherPhone}</p>
          <p>Billed To:</p>
          <p>Darsoon Inc.</p>
          <p>6D - 7398 Yonge St, PMB 754, Thornhill, ON L4J8J2</p>
          <p>Canada</p>
        </div>
      </div>
      {/* <p className="italic c-gray11 mt-4">
        This invoice confirms that the amount of {frmCAD(rows.reduce((acc, r) => (acc += r["Cost"]), 0))}{" "}
        {giftsSum > 0 && `plus ${giftsSum} as gift`} is accurate for my tutoring services provided in{" "}
        {selectedMonth.yearMonth}. I, {teacherName}, approve this amount for payment by Darsoon.
      </p> */}
      <table className="w-full border border-gray-300 rounded-lg overflow-hidden">
        <thead className="bg-gray-100">
          <tr className="border-b border-gray-300">
            <th className="text-left px-4 py-2">Description</th>
            <th className="text-left px-4 py-2">Session</th>
            <th className="text-left px-4 py-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-gray-300">
            <td className="px-4 py-2">Teaching Services</td>
            <td className="px-4 py-2">{rows.length} Sessions</td>
            <td className="px-4 py-2">{frmCAD(rows.reduce((acc, r) => (acc += r["Cost"]), 0))}</td>
          </tr>
          {!!giftsSum && (
            <tr className="border-b border-gray-300">
              <td className="px-4 py-2">Gift</td>
              <td className="px-4 py-2">-</td>
              <td className="px-4 py-2">{frmCAD(giftsSum)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
