import { NavLink } from "./Lnk";

const UsersLayout = () => {
  const baseHref = "/admin-dashboard/users";
  return (
    <nav aria-label="user management" className="flex gap-2 flex-wrap text-xs">
      <NavLink baseHref={baseHref} page="" label="View all Users" icon="bf-i-ph-users-three" />
      <NavLink baseHref={baseHref} page="add-user" label="Add a New User" icon="bf-i-ph-user-circle-plus" />
    </nav>
  );
};

export default UsersLayout;
